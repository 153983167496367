import React, { useEffect, useRef } from "react";
import Login from "./login";
import Signup from "./signup";
import Forget from "./forget";

function OverlayMessage({ currentView, onClose, onPasswordSubmit, onSignup, onForget, onLogin }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the modalRef exists and the clicked element is not inside it...
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        console.log("Clicked outside modal, closing overlay");
        onClose();
      }
    };

    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    
    // Remove the event listener on cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!currentView) return null;

  return (
    <div className="overlay" style={{ zIndex: 1100 }}>
      {/* Apply the ref to the modal content */}
      <div className="overlay-box" ref={modalRef}>
        {currentView === "signup" && (
          <Signup onClose={onClose} onLogin={onLogin} />
        )}
        {currentView === "login" && (
          <Login 
          onClose={onClose} 
          onPasswordSubmit={onPasswordSubmit} 
          onSignup={onSignup} 
          onForget={onForget} 
          />
        )}
        {currentView === "forget" && (
          <Forget onClose={onClose} onLogin={onLogin} />
        )}
      </div>
    </div>
  );
}

export default OverlayMessage;
