import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Sidebar from './components/sidebar';
import ChatWindow from './components/chat';
import Login from './components/login';
import Signup from './components/signup';
import Forget from './components/forget';
import Main from './components/Mainpage';
import Bottomsearchbar from './components/bottomsearchbar';
import UserProfile from './components/userprofile';
import CustomAlert from "./components/CustomAlert";
import './App.css';

function App() {
  const [messageCount, setMessageCount] = useState(0); // Track message count
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);

  // Function to update message count
  const incrementMessageCount = () => {
    setMessageCount(prevCount => prevCount + 1);
  };

  return (
    <Router>
      <div className="app-container">
      <CustomAlert />
        <Routes>
          <Route path="/" element={<Main />} />
          {/* Additional routes can be added as needed */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
