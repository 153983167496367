import axios from "axios";
import Cookies from 'js-cookie';
const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const createUser = async (email, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/create_user`, {
        email,
        password
      });
      return response; // Assuming the backend responds with user data or confirmation
    } catch (error) {
      throw new Error(error.response?.data?.detail || "Failed to create user.");
    }
  };
  
  export const verifyOtp = async (email, enteredOtp) => {
    try {
        // console.log(API_BASE_URL);
        const response = await axios.post(
          `${API_BASE_URL}/verify-otp/?email=${encodeURIComponent(email)}&entered_otp=${encodeURIComponent(enteredOtp)}`
        );
      return response.data; // Assuming the backend confirms the OTP verification
    } catch (error) {
      throw new Error(error.response?.data?.detail || "OTP verification failed.");
    }
  };
  