export const handleStorageChange = (setConversation) => {
  try {
    const stored = localStorage.getItem("conversation");
    if (stored) {
      setConversation(JSON.parse(stored));
    }
  } catch (error) {
    console.error("Error fetching conversation from localStorage:", error);
  }
};
