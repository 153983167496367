import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import ScrollToBottom, { useScrollToBottom, useScrollToTop  } from "react-scroll-to-bottom";

import UserProfile from "./userprofile.jsx";
import PulseLoader from "./PulseLoader.jsx";
import AnswerBox from "./Answer"; // Ensure correct import path
import BottomSearchBar from "./bottomsearchbar"; 
import "../Css/chat.css";

import buy_search_mpn from "../assets/icons/svg/buy-mpn-search.svg";
import generic_query from "../assets/icons/svg/generic-query.svg";
import discontinued_alternate from "../assets/icons/svg/discontinued-alternative.svg";
import brand_category from "../assets/icons/svg/brand-category.svg";

import { fetchAnswerFromQuery } from "../service/chat";
import { getVisitorSessions, incrementMessageCounter } from "../service/apiService";
import { v4 as uuidv4 } from "uuid";
import { handleStorageChange } from "./storageHandler";
import { saveChatWithRetries } from "../service/sendChatToDb"

const Chat = ({ sessionId, email, visitorId, onLimitReached, onSignup }) => {
  const [conversation, setConversation] = useState(() => {
    const stored = localStorage.getItem("conversation");
    // Mark all loaded messages as not new (i.e. disable typing animation)
    return stored
      ? JSON.parse(stored).map((msg) => ({ ...msg, isAnswerFromThisChat: false }))
      : [];
  });

  useEffect(() => {
    // Function to fetch the latest conversation
    const updateConversation = () => handleStorageChange(setConversation);

    // Listen for changes in `localStorage` from other tabs
    window.addEventListener("storage", updateConversation);

    // Listen for changes in `localStorage` in the same tab
    const checkStorageChange = setInterval(() => {
      const stored = localStorage.getItem("conversation");
      if (stored) {
        setConversation(JSON.parse(stored));
      }
    }, 500);

    return () => {
      window.removeEventListener("storage", updateConversation);
      clearInterval(checkStorageChange);
    };
  }, []);

  const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
  const [input, setInput] = useState("");
  const chatContainerRef = useRef(null);
  // const scrollToBottom = useScrollToTop({scrollTop: "100%"});
  const bottomRef = useRef(null);
  // useEffect(() => {
  //   forceScrollToBottom();
  // }, [conversation.length]);

  // const forceScrollToBottom = () => {
  //   console.log("Scrolling to bottom...");
  //   console.log(conversation.length);
  //   scrollToBottom();
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  //   }
  // };
//   const conversationData = localStorage.getItem("conversation");
// const hasRunOnce = sessionStorage.getItem("newChatTriggered"); // Now using sessionStorage

// // Function to check if the conversation is empty
// const isConversationEmpty = !conversationData || JSON.parse(conversationData).length === 0 || conversationData === "[]";

// // Run only if conversation is empty and it has not been triggered before
// console.log(isConversationEmpty+hasRunOnce)
// if (isConversationEmpty && !hasRunOnce) {
//   const newChatPayload = {
//     sessionId,
//     email: email || "guest@gmail.com",
//     title: "New Chat",
//     timestamp: new Date().toISOString(),
//     isEmptyChat: true,
//   };

//   window.dispatchEvent(new CustomEvent("newChatButton", { detail: newChatPayload }));

//   // Mark as triggered so it doesn't run again until reset
//   sessionStorage.setItem("newChatTriggered", "true"); // Stored in sessionStorage
// }

// Reset the flag when conversation gets populated
// window.addEventListener("storage", () => {
//   const updatedConversation = localStorage.getItem("conversation");

//   if (updatedConversation && JSON.parse(updatedConversation).length > 0) {
//     sessionStorage.removeItem("newChatTriggered"); // Reset only when conversation is not empty
//   }
// });

  // Save conversation to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("conversation", JSON.stringify(conversation));
  }, [conversation]);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const version = "v4";

  const handleSubmit = async () => {
    if (!input.trim()) return;
    // forceScrollToBottom()
    const userMessage = {
      id: Date.now(),
      user: "User",
      prompt: input,
      answer: "",
      backendData: null,
      isAnswerFromThisChat: false,
    };

    setConversation((prev) => [...prev, userMessage]);
    setInput("");
    setIsFetchingAnswer(true);      
    if (conversation.length === 0 && localStorage.getItem("newChatTriggered") === 'false') {          
      const newChatPayload = {
        sessionId,
        email: email || "guest@gmail.com",
        title: "New Chat",
        timestamp: new Date().toISOString(),
        isEmptyChat: true,
      };
      window.dispatchEvent(new CustomEvent("newChatButton", { detail: newChatPayload }));
      localStorage.setItem("newChatTriggered", "true");
    }
    try {
      // const sessionData = await getVisitorSessions(visitorId, email);
      // if (sessionData !== null) {
      //   if (sessionData.total_message_count >= 100) {
      //     onLimitReached();
      //     return; // Exit to prevent further API calls
      //   }
      // }

      // Make API call
      const response = await fetchAnswerFromQuery({
        query: input,
        userId: email,
        sessionId: localStorage.getItem("sessionId"),
        messageId: Date.now().toString(),
      });
      const chatMROText =
        response && response.length > 1 ? response[1] : "No response received.";

      // Add ChatMRO's response to the conversation
      const chatMROMessage = {
        id: Date.now() + 1,
        user: "ChatMRO",
        prompt: "",
        answer: chatMROText,
        backendData: response[0],
        isAnswerFromThisChat: true, // The newest response triggers typing
      };

      setConversation((prev) => [...prev, chatMROMessage]);
      const chatPayload = {
        id: `${Date.now()}`, // Timestamp as string
        email: email || "guest@gmail.com",
        user: "User",
        prompt: input,
        sessionId: localStorage.getItem("sessionId"),
        answer: chatMROText,
        backendAnswer: response[0],
        isAnswerFromThisChat: true,
        version: 'v4',
      };
      
      // Dispatch an event when a response is received, so we can update the chat title
      window.dispatchEvent(new CustomEvent('botResponseReceived', {
        detail: {
          sessionId: localStorage.getItem("sessionId"),
          userMessage: input,
          botResponse: chatMROText
        }
      }));
      // Check if this is the first message in a new chat session
      const isNewChat = conversation.length <= 1; // Just the user message we added
      
      // If this is a new chat, include the title for our custom event
      const title = input.length > 60 ? input.substring(0, 57) + '...' : input;
      
      // Check if this message is being sent from an empty "New Chat" entry
      const isEmptyNewChat = localStorage.getItem("lastNewSessionId") === localStorage.getItem("sessionId");
      
      // Run chat saving in background without breaking the chat flow
      saveChatWithRetries(chatPayload, 3, 5000)
      .then((result) => {
        if (!result) {
          console.log("saving chat");
        } else {
          // For new chats, pass enough information to directly update the recent chats list
          // without having to make a new API call
          if (isNewChat) {
            // Create a timestamp for the chat
            const timestamp = new Date().toISOString();
            
            // Dispatch event with additional data for new chats
            window.dispatchEvent(new CustomEvent('newChatSaved', { 
              detail: {
                ...chatPayload,
                isNewChat: true,
                timestamp: timestamp,
                title: title,
                isEmptyNewChat: isEmptyNewChat // Flag if this was an empty "New Chat" entry
              } 
            }));
            
            // Clear lastNewSessionId if we just sent a message from the empty chat
            if (isEmptyNewChat) {
              localStorage.removeItem("lastNewSessionId");
            }
          } else {
            // Regular update for existing chats
            window.dispatchEvent(new CustomEvent('newChatSaved', { detail: chatPayload }));
          }
        }
      })
      .catch((err) => {
        console.error("Unexpected error during chat save:", err);
      });
      setIsFetchingAnswer(false)
      await incrementMessageCounter({ sessionId, email, fingerprint: visitorId, version });
    } catch (err) {
      console.error("Error fetching response:", err);
      const errorMessage = {
        id: Date.now() + 2,
        user: "ChatMRO",
        prompt: "",
        answer: `Error: ${err.message || "Network Error"}`,
        backendData: null,
        isAnswerFromThisChat: false,
      };
      // setConversation((prev) => [...prev, errorMessage]);
    } finally {
      setIsFetchingAnswer(false);
    }
  };

  const handleSignup = () => {
    onSignup();
  };
  const handlelogin = () => {
    onLimitReached();
  };

  return (
    <div className="main-container">      
      <header className="header">
        {localStorage.getItem("email") === "guest@gmail.com" ? (
          <div className="auth-buttons">
            <button className="login-btn" onClick={handlelogin}>
              Log In
            </button>
            <button className="signup-btn" onClick={handleSignup}>
              Sign Up
            </button>
          </div>
        ) : (
          <UserProfile />
        )}
      </header>
      
      {conversation.length === 0 && !isFetchingAnswer && (
        <div className="main-page">
          <div className="input-container">
            <div className="heading">
              <h2 className="hello">Hello,</h2>
              <div className="caption">
                <h2>How can I help you today?</h2>
              </div>
            </div>
            <BottomSearchBar
              // className={conversation.length > 0 ? "bottom" : ""}
              prompt={input}
              onChange={handleChange}
              handleSubmit={handleSubmit}
              isFetchingAnswer={isFetchingAnswer}
              ref={bottomRef}
              placeholder="Ask anything..."
              styles={{ marginRight: "3rem" }}
            />
          </div>
          <div className="task-buttons">
            <button>
              <img src={buy_search_mpn} alt="Buy/Search MPN" />
              Buy/Search MPN
            </button>
            <button>
              <img src={generic_query} alt="Generic Query" />
              Generic Query
            </button>
            <button>
              <img src={discontinued_alternate} alt="Discontinued/Alternative" />
              Discontinued/Alternate
            </button>
            <button>
              <img src={brand_category} alt="Brand/Category" />
              Brand/Category
            </button>
          </div>
        </div>
      )}

      {(conversation.length > 0 || isFetchingAnswer) && (
        <div className="chat-container" ref={chatContainerRef}>
          <ScrollToBottom
            className="main-box"
            followButtonClassName="follow-button"
            initialScrollBehavior="smooth"
            mode="bottom"
            style={{ maxHeight: "100%", boxSizing: "border-box" }}
          >          
            {conversation.map((msg) => (
              <AnswerBox
                key={msg.id}
                user={msg.user}
                prompt={msg.prompt}
                answer={msg.answer}
                backendData={msg.backendData ? JSON.stringify(msg.backendData) : ""}                
                isAnswerFromThisChat={msg.isAnswerFromThisChat}
                isFetchingAnswer={isFetchingAnswer}
              />
            ))}
            
            {isFetchingAnswer && (
              <div style={{ marginLeft: "2.5rem" }}>
                <PulseLoader />
              </div>
            )}
          </ScrollToBottom>
        </div>
      )}
      
      {conversation.length > 0 && (
        <BottomSearchBar
          className={conversation.length > 0 ? "bottom" : ""}
          prompt={input}
          onChange={handleChange}
          handleSubmit={handleSubmit}
          isFetchingAnswer={isFetchingAnswer}
          ref={bottomRef}
          placeholder="Ask anything..."
          styles={{ marginRight: "3rem" }}
        />
      )}
      
    </div>
  );
};

export default Chat;
