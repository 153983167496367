export const saveChatToBackend = async (chatData) => {
    const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

    try {
      const response = await fetch(`${API_BASE_URL}/chats/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(chatData),
      });
      if (!response.ok) {
        throw new Error(`Error saving chat: ${response.statusText}`);
      }
      return await response.json();
    } catch (error) {
      console.error("Failed to save chat:", error);
      throw error;
    }
  };
  
  // Function to retry saving the chat with a delay
  export const saveChatWithRetries = async (chatData, maxRetries = 3, delay = 5000) => {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const result = await saveChatToBackend(chatData);
        console.log(`Chat saved successfully on attempt ${attempt}`);
        return result; // Success, exit the loop
      } catch (error) {
        if (attempt < maxRetries) {
          console.warn(`Attempt ${attempt} failed. Retrying in ${delay / 1000} seconds...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          console.error("All attempts to save chat failed.");
          throw error;
        }
      }
    }
  };
  