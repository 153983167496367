// authApi.js
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// 1) Send OTP
export const sendOtp = async (email, subject) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/send-otp`, {
      email,
      subject,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.detail || "Failed to send OTP.");
  }
};

// 2) Verify OTP
export const verifyOtpApi = async (email, enteredOtp) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/verify-otp/?email=${encodeURIComponent(
        email
      )}&entered_otp=${encodeURIComponent(enteredOtp)}`
    );
    return response.data; // e.g., { message: "OTP verified successfully" }
  } catch (error) {
    throw new Error(error.response?.data?.detail || "OTP verification failed.");
  }
};

// 3) Update Password
export const updatePassword = async (email, newPassword) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/update-password`, {
      email,
      new_password: newPassword,
    });
    return response.data; // e.g., { message: "Password updated successfully" }
  } catch (error) {
    throw new Error(
      error.response?.data?.detail || "Password update failed."
    );
  }
};
