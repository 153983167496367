import React from "react";
import "../Css/PulseLoader.css"; // Import the CSS for styling

const PulseLoader = () => {
  return (
    <div className="pulse-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default PulseLoader;
