// service/fetchRecentChats.js

export async function fetchRecentChats(email) {
  // Make sure to set REACT_APP_BACKEND_BASE_URL in your .env file
  const url = process.env.REACT_APP_BACKEND_BASE_URL; // e.g. "https://chatmro.com/backend/chats/titles"

  try {
    const response = await fetch(url + `/chats/titles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // Adjust the body key here if needed. For example:
      // body: JSON.stringify({ user_id: email })
      // If your backend specifically expects { string: "someValue" }, use that key:
      body: JSON.stringify({ id: email }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch recent chats");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching recent chats:", error);
    return {}; // Return an empty object in case of error
  }
}

// Function to fetch only the latest chat title for a specific session
export async function fetchLatestChatTitle(sessionId) {
  if (!sessionId) return null;
  
  const url = process.env.REACT_APP_BACKEND_BASE_URL;
  
  try {
    // This uses the same endpoint but we'll extract just the needed session
    const response = await fetch(url + `/chats/messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: sessionId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch chat info");
    }

    const data = await response.json();
    
    // Find the first user message to use as title
    if (data && data.messages && data.messages.length > 0) {
      // Look for first user message
      const firstUserMessage = data.messages.find(msg => msg.sender === "User");
      if (firstUserMessage) {
        return {
          title: firstUserMessage.msg.length > 60 
            ? firstUserMessage.msg.substring(0, 57) + '...' 
            : firstUserMessage.msg,
          timestamp: firstUserMessage.timestamp || new Date().toISOString()
        };
      }
    }
    
    // If no messages found, return null
    return null;
  } catch (error) {
    console.error("Error fetching chat title:", error);
    return null;
  }
}
  
export async function fetchRecentChatsconveration(sessionId) {
  // Make sure to set REACT_APP_BACKEND_BASE_URL in your .env file
  const url = process.env.REACT_APP_BACKEND_BASE_URL; // e.g. "https://chatmro.com/backend/chats/titles"

  try {
    const response = await fetch(url + `/chats/messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: sessionId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch recent chats");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching recent chats:", error);
    return {}; // Return an empty object in case of error
  }
}
  