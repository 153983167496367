import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { motion } from "framer-motion";
import Cross_icon from "../assets/icons/svg/cross-icon.svg";
import styles from '../Css/login.module.css';


// Icons / Images
import User_icon from "../assets/icons/svg/user_icon.png";
import ChatMRO from "../assets/icons/svg/favicon.png";
import ThumbsUpIcon from "../assets/icons/svg/like.svg";
import ThumbsDownIcon from "../assets/icons/svg/dislike.svg";
import OkIcon from "../assets/icons/feedback.png";

// Feedback API
import { sendFeedback } from "../service/sendFeedback";

// Loader
import PulseLoader from "./PulseLoader";

// 1) Wrap entire component with React.memo
const Answer = React.memo(
  ({
    user,
    prompt,
    answer,
    isFetchingAnswer = false,
    isAnswerFromThisChat = false,
  }) => {
    // States for typing effect and feedback
    const [typedText, setTypedText] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState(null);
    const [textFeedback, setTextFeedback] = useState("");
    const [showInputBox, setShowInputBox] = useState(false);
    const [copied, setCopied] = useState(false);
    const [TextToCopy, setTextToCopy] = useState("");
    const [prevAnswer, setPrevAnswer] = useState("");
    // 2) Updated cleanHTML function
    useEffect(() => {
      if (answer) {
        const displayText = typeof answer === "string" ? answer : JSON.stringify(answer);        
        setTextToCopy(displayText); // Updates only when `answer` changes
      }
    }, [answer]); 

    
    useEffect(() => {
      const links = document.querySelectorAll(".answer-bold a");
      links.forEach(link => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer");
  
        // Ensure absolute URL
        const href = link.getAttribute("href");
        if (href && !href.startsWith("http://") && !href.startsWith("https://")) {
          link.setAttribute("href", `https://${href}`);
        }
      });
    }, [typedText]);
    const cleanHTML = (html) => {
      // Convert markdown to HTML
      const markdownToHtml = marked.parse(html);

      // Add <html> tags (optional, if needed in your case)
      // let htmlWithTags = `<html>${markdownToHtml}</html>`;

      // Remove excessive <br> tags (e.g., more than two consecutive)
      // let cleanedHTML = htmlWithTags.replace(
      //   /(<br\s*\/?\s*>){2,}/g,
      //   "<br><br>"
      // );

      // Replace occurrences of ": " with a space
      // cleanedHTML = cleanedHTML.replace(/:\s+/g, " ");
      // cleanedHTML = cleanedHTML.replace(/<!DOCTYPE\s*[^>]*>/g, "");  // More robust pattern


      // Finally, sanitize
      return DOMPurify.sanitize(markdownToHtml, { USE_PROFILES: { html: true } });
    };
    useEffect(() => {
      if (typeof answer === "string") {
        let currentIndex = 0;
        let interval;
        let wasTabHidden = false; // Track if the user switched tabs

        const handleVisibilityChange = () => {
          if (document.hidden) {
            wasTabHidden = true; // User left the tab
          } else if (wasTabHidden) {
            // If user returns and the typing wasn't finished, show the full answer
            setTypedText(answer);
            setIsTyping(false);
            setShowFeedback(true);
            clearInterval(interval);
          }
        };

        if (isAnswerFromThisChat) {
          const chars = answer.split('');
          setIsTyping(true);
          // Optionally show feedback (if needed) during typing:
          setShowFeedback(true);
    
          interval = setInterval(() => {
            if (currentIndex <= chars.length) {
              setTypedText(chars.slice(0, currentIndex).join(''));
              currentIndex++;
            } else {
              clearInterval(interval);
              setIsTyping(false);
              setShowFeedback(true);
            }
          }, 5); // Adjust speed for smoother typing effect

          document.addEventListener("visibilitychange", handleVisibilityChange);
        } else {
          // For answers loaded from storage, show full text immediately.
          setTypedText(answer);
          setShowFeedback(true);
        }
        return () => {
          clearInterval(interval)
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        };

      } else {
        // In case answer is not a string
        setTypedText(answer);
        setIsTyping(false);
        setShowFeedback(true);
      }
    }, [answer, isAnswerFromThisChat]);
    
    // Renders typedText as HTML
    const renderAnswer = () => {
      return <div dangerouslySetInnerHTML={{ __html: cleanHTML(typedText) }} />;
    }

    // Feedback logic
    const handleFeedback = async (type, extra = "") => {
      setFeedback(type);
      setShowInputBox(false);

      console.log("User feedback:", type, extra);

      const feedbackData = {
        session_id: localStorage.getItem("sessionId") || "guest-session",
        query: prompt,
        answer_data: typedText,
        feedback: type === "ok" ? extra : type,
      };

      try {
        await sendFeedback(feedbackData);
        console.log("Feedback successfully sent!");
      } catch (error) {
        console.error("Error sending feedback:", error);
      }
    };

    const handleButtonClickcopy = () => {
      // Perform your copy logic here
      // For example, copy some text to the clipboard
      navigator.clipboard.writeText(TextToCopy).then(() => {
        setCopied(true);
        // Reset "Copied" after 2 seconds so user can copy again.
        setTimeout(() => {
          setCopied(false);
        }, 5000);
      });
    };
    

    const handleButtonClick = (type) => {
      if (type === "ok") {
        setShowInputBox((prev) => !prev); // Toggle only when type is "ok"
        setFeedback(type);
      } else {
        handleFeedback(type);
      }
    };

    // For the "User" bubble icon
    const initial =
      (localStorage.getItem("email") || "")
        .split("@")[0]
        ?.charAt(0)
        ?.toUpperCase() || "";

    const handleSubmit = (e) => {
      e.preventDefault();
      handleFeedback("ok", textFeedback);
    };

    // If it's a user bubble
    if (user === "User") {
      return (
        <div className="chat-box">
          <div className="chat-icon">
            <div className="user_icon">{initial}</div>
            <div className="font-bold">User</div>
          </div>
          <div className="user_response">
            <div className="prompt-bold">{prompt}</div>
          </div>
          <div className="chat-MRO">
          <img src={ChatMRO} alt="ChatMRO icon" width={32} height={32} />
          <div className="font-bold">ChatMRO</div>
        </div>
        </div>
        
      );
    }

    // Otherwise, ChatMRO bubble
    return (
      <div className="chatMRO-Icon">
        {/* <div className="chat-MRO">
          <img src={ChatMRO} alt="ChatMRO icon" width={32} height={32} />
          <div className="font-bold">ChatMRO</div>
        </div> */}
        <div>
        <div className="answer-bold">
            {/* {displayedText} */}
            {renderAnswer()}
            {/* Show "Typing..." if the text is still typing out */}
            {/* {isTyping && (
              // <div style={{ color: "#666", marginTop: "0.5rem" }}>
              //   Typing...
              // </div>
            )} */}
          </div>

          {!isTyping && showFeedback && (
  <div className="feedback-buttons">
    {/* Thumbs Up Button */}
   
<button
  onClick={() => handleFeedback("thumbs_up")}
  
>
  {feedback === "thumbs_up" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="-9 -9 32 32"
    >
      <path
        id="Path_4942"
        data-name="Path 4942"
        d="M16.964,23H10.643a1,1,0,0,1-1-1V14.018a2.114,2.114,0,0,1,.428-1.25L13.732,8.2a.474.474,0,0,1,.393-.2,1.365,1.365,0,0,1,1.018.465,1.422,1.422,0,0,1,.357,1.09l-.286,2.911a.487.487,0,0,0,.5.535h3.875a1.476,1.476,0,0,1,1.482,1.518,7.194,7.194,0,0,1-.679,3.054L18.3,22.125A1.464,1.464,0,0,1,16.964,23Zm-9.41,0H6.018A1.014,1.014,0,0,1,5,22V14a1.014,1.014,0,0,1,1.018-1H7.554a1,1,0,0,1,1.018,1v8a1.014,1.014,0,0,1-1.018,1Z"
        transform="translate(-5 -8)"
        fill="#000"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      transform="translate(0 0)"
      viewBox="15 13 32 32"
    >
      <motion.g
        animate={
          feedback === "thumbs_up"
            ? { scale: [1, 1.2, 1], y: [-12, 2, 0] }
            : { scale: 1, y: 0 }
        }
        transition={{
          duration: 1,
          ease: [0.16, 1, 0.3, 1],
        }}
      >
        <path
          id="Path_4440"
          data-name="Path 4440"
          d="M39.206,29.469,38.4,34.1a3.805,3.805,0,0,1-3.559,2.877H26.787a.891.891,0,0,1-.89-.89v-9a.891.891,0,0,1,.89-.89h2.235l4.643-5.076a.433.433,0,0,1,.252-.132,1.29,1.29,0,0,1,.171-.009,2.026,2.026,0,0,1,1.935,1.468c.343.973-.789,2.951-1.453,3.986h2.167a2.437,2.437,0,0,1,2.469,3.036ZM26.729,27.086v9a.059.059,0,0,0,.058.058h2V27.029h-2A.059.059,0,0,0,26.729,27.086Zm11.364.773a1.7,1.7,0,0,0-1.356-.594H33.789a.416.416,0,0,1-.334-.665c.943-1.263,1.972-3.342,1.783-3.877a1.2,1.2,0,0,0-1.079-.91l-4.538,4.961v9.372h5.22a2.972,2.972,0,0,0,2.742-2.2l.806-4.633A1.7,1.7,0,0,0,38.093,27.86Z"
          fill={feedback === "thumbs_up" ? "#000" : "#000"}
          stroke="#000"
        strokeWidth="0.2"
        />
      </motion.g>
    </svg>
  )}
  <span>Like</span>
</button>

<button
  onClick={() => handleFeedback("thumbs_down")}
>
  {feedback === "thumbs_down" ? (
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="-9 -9 32 32"
    >
      <path
        id="Path_4943"
        data-name="Path 4943"
        d="M9.108,8h6.321a1,1,0,0,1,1,1v7.982A2.114,2.114,0,0,1,16,18.232L12.34,22.8a.474.474,0,0,1-.393.2,1.365,1.365,0,0,1-1.018-.465,1.422,1.422,0,0,1-.357-1.09l.286-2.911a.487.487,0,0,0-.5-.535H6.483A1.476,1.476,0,0,1,5,16.482a7.194,7.194,0,0,1,.679-3.054L7.768,8.875A1.464,1.464,0,0,1,9.108,8Zm9.41,0h1.536a1.014,1.014,0,0,1,1.018,1v8a1.014,1.014,0,0,1-1.018,1H18.518A1,1,0,0,1,17.5,17V9a1.014,1.014,0,0,1,1.018-1Z"
        transform="translate(-5 -8)"
        fill="#000"
        
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="-9 -9 32 32"
    >
      <path
        id="Path_4440"
        data-name="Path 4440"
        d="M25.962,28.489l.806-4.633a3.805,3.805,0,0,1,3.559-2.877h8.055a.891.891,0,0,1,.89.89v9a.891.891,0,0,1-.89.89H36.146L31.5,36.838a.433.433,0,0,1-.252.132,1.29,1.29,0,0,1-.171.009,2.026,2.026,0,0,1-1.935-1.468c-.343-.973.789-2.951,1.453-3.987H28.431a2.437,2.437,0,0,1-2.47-3.036Zm12.478,2.383v-9a.059.059,0,0,0-.058-.058h-2v9.118h2A.059.059,0,0,0,38.439,30.872ZM27.075,30.1a1.7,1.7,0,0,0,1.356.594h2.948a.416.416,0,0,1,.334.665c-.943,1.263-1.972,3.342-1.783,3.877a1.2,1.2,0,0,0,1.08.91l4.538-4.961V21.811h-5.22a2.973,2.973,0,0,0-2.742,2.2l-.806,4.633A1.7,1.7,0,0,0,27.075,30.1Z"
        transform="translate(-25.797 -20.879)"
        fill="#000"
        stroke="#000"
        strokeWidth="0.2"
      />
    </svg>
  )}
  <span>Dislike</span>
</button>

    {/* Copy Button */}
    <button
      onClick={handleButtonClickcopy}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32">
        <circle cx="16" cy="16" r="16" fill="transparent" />
        <g transform="translate(-12.09 3.85)">
          <path
            d="M22.909,4.15h6.8A1.824,1.824,0,0,1,31.53,5.969V7.315H33.6a1.824,1.824,0,0,1,1.819,1.819v9.2A1.824,1.824,0,0,1,33.6,20.15H26.8a1.824,1.824,0,0,1-1.819-1.819V16.985H22.909a1.824,1.824,0,0,1-1.819-1.819v-9.2A1.824,1.824,0,0,1,22.909,4.15Zm8.62,4.238v6.778a1.824,1.824,0,0,1-1.819,1.819H26.055v1.346a.75.75,0,0,0,.747.747h6.8a.75.75,0,0,0,.747-.747v-9.2a.75.75,0,0,0-.747-.747H31.53ZM29.71,5.223h-6.8a.75.75,0,0,0-.747.747v9.2a.75.75,0,0,0,.747.747h6.8a.75.75,0,0,0,.747-.747v-9.2a.75.75,0,0,0-.747-.747Z"
            fill="#000000"
            />
        </g>
      </svg>
      <span>{copied ? "Copied" : "Copy"}</span>
    </button>

    {/* Feedback Button */}
    <button
      onClick={() => handleButtonClick("ok")}
      
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        >
        <circle cx="16" cy="16" r="16" fill="#fff" />
        <path
          d="M21.1,14.44H19.322V12.906A2.42,2.42,0,0,0,16.9,10.489H7.275a2.42,2.42,0,0,0-2.418,2.418v6.311a2.42,2.42,0,0,0,2.418,2.418H8.592v2.173a1.235,1.235,0,0,0,.686,1.12,1.264,1.264,0,0,0,.575.141,1.248,1.248,0,0,0,.735-.243l1.819-1.32a2.028,2.028,0,0,0,.814.172h1.553l3.583,2.6a1.078,1.078,0,0,0,.634.21,1.091,1.091,0,0,0,.5-.122,1.067,1.067,0,0,0,.592-.967V23.678H21.1a2.038,2.038,0,0,0,2.036-2.036V16.475A2.038,2.038,0,0,0,21.1,14.44ZM10.218,24.318a.629.629,0,0,1-1-.509V21.321a.314.314,0,0,0-.314-.314H7.274a1.792,1.792,0,0,1-1.79-1.79V12.906a1.792,1.792,0,0,1,1.79-1.79H16.9a1.792,1.792,0,0,1,1.79,1.79v6.311a1.792,1.792,0,0,1-1.79,1.79H14.882a.309.309,0,0,0-.184.06ZM22.51,21.642A1.41,1.41,0,0,1,21.1,23.05H19.766a.314.314,0,0,0-.314.314V25.4a.458.458,0,0,1-.727.371L15.058,23.11a.315.315,0,0,0-.184-.06H13.219a1.354,1.354,0,0,1-.17-.011l1.935-1.4H16.9a2.42,2.42,0,0,0,2.418-2.418V15.067H21.1a1.41,1.41,0,0,1,1.409,1.409Zm-6.934-5.266H8.6a.314.314,0,1,1,0-.628h6.972a.314.314,0,1,1,0,.628Z"
          transform="translate(1.643 -1.989)"          
          fill="#000000"
          stroke="#000000"
          strokeWidth="0.4"
        />
      </svg>
      <span>Feedback</span>
    </button>
  </div>
)}

{showInputBox && (
  <div className="feedback-form-container" style={{ position: "relative" }}>
  {/* Close button with image */}
  <img  
    src={Cross_icon}
    onClick={() => setShowInputBox(false)}
    alt="cross_icon"
    style={{
      position: "absolute",
      width:"20px",
      zIndex:999,
      top: "-3px",
      right: "10px",
      cursor: "pointer",
    }}
  />

  <form
    onSubmit={handleSubmit}
    className="feedback-input-form"
    style={{ marginTop: "8px" }}
  >
    <input
      type="text"
      className="form-control"
      id="feedbackInput"
      value={textFeedback}
      onChange={(e) => setTextFeedback(e.target.value)}
      placeholder="Enter your feedback"
      required
    />
    <button type="submit" className="btn btn-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.002"
        height="18"
        viewBox="0 0 18.002 18"
      >
        <path
          d="M29.507,12.5H29.5l-.054,0a.965.965,0,0,0-.266.052L13.2,17.545a1,1,0,0,0-.053,1.891L20.783,22.3l3.824,7.649a1,1,0,0,0,.894.553,1.073,1.073,0,0,0,.11-.006,1,1,0,0,0,.862-.752l3.983-15.932a1,1,0,0,0-.949-1.311Zm-1.035,1.322-7.485,7.485L13.5,18.5ZM25.5,29.5l-3.765-7.528,7.529-7.529Z"
          transform="translate(-12.502 -12.5)"
          fill="rgba(27,33,49,0.6)"
        />
      </svg>
    </button>
  </form>
  </div>
)}

        </div>
      </div>
    );
  },
  // Memo: skip re-render if these props haven't changed
  (prev, next) =>
    prev.user === next.user &&
    prev.prompt === next.prompt &&
    prev.answer === next.answer &&
    prev.isFetchingAnswer === next.isFetchingAnswer &&
    prev.isAnswerFromThisChat === next.isAnswerFromThisChat
);

export default Answer;
