import React, { useState, useEffect, useRef } from "react";
import styles from "../Css/forget.module.css"; // Assuming the same CSS file is used
import Cross_icon from "../assets/icons/svg/cross-icon.svg";
import verify from "../assets/icons/svg/Verifylogo.svg";

// Import your API functions
import { sendOtp, verifyOtpApi, updatePassword } from "../service/passward_reset"; // <-- adjust the import path!

const ForgetPassword = ({ onClose, onLogin }) => {
  const [inputValues, setInputValues] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [currentPopup, setCurrentPopup] = useState("forgetpop");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [canResend, setCanResend] = useState(true);
  const inputsRef = useRef([]);
  const [isforgetting , setIsforgetting] = useState(false);
  const [activeField, setActiveField] = useState(null);

  // ------------------------------------
  // TIMER: for 2-minute OTP expiration
  // ------------------------------------
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timerId);
    } else {
      setCanResend(false);
    }
  }, [timeLeft]);

  // ------------------------------------
  // GENERAL HANDLERS
  // ------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null })); // Clear specific error
    }
  };

  const handleFocus = (field) => {
    setActiveField(field); // Set the active field when an input is focused
  };

  const handleBlur = (field) => {
    if (!inputValues[field]) {
      setActiveField(null); // Clear the active field if the input is empty and blurred
    }
  };

  // ------------------------------------
  // 1) SEND OTP (EMAIL INPUT)
  // ------------------------------------
  const handleSubmitEmail = async (event) => {
    event.preventDefault();
    const { email } = inputValues;
    setIsforgetting(true)

    // Basic validation
    if (!email.includes("@")) {
      setErrors((prev) => ({
        ...prev,
        email: "Please include an '@' in the email address.",
      }));
      return;
    }

    try {
      // Call your API to send the OTP
      await sendOtp(email, "Forget passward verification");

      // Move to OTP popup on success
      setCurrentPopup("otp");
    } catch (error) {
      setIsforgetting(false)
      // Handle error from the API
      setErrors((prev) => ({
        ...prev,
        email: error.message || "Failed to send OTP.",
      }));
    }
  };

  // ------------------------------------
  // OTP Input Handling
  // ------------------------------------
  const handleOtpChange = (value, index) => {
    // Only allow digits
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to next box if a digit was entered
      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    // If user presses Backspace on an empty field, jump back
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const resendOtp = () => {
    if (canResend) {
      alert("New OTP sent!");
      setOtp(["", "", "", ""]);
      setTimeLeft(120);
      setCanResend(true);
      inputsRef.current[0]?.focus();
    } else {
      alert("Cannot resend OTP. Please refresh to restart.");
    }
  };

  // ------------------------------------
  // 2) VERIFY OTP
  // ------------------------------------
  const verifyOtp = async () => {
    const enteredOtp = otp.join("");

    // Basic validation
    if (enteredOtp.length < 4) {
      alert("Please enter a 4-digit OTP");
      return;
    }

    // Check if OTP has expired
    if (timeLeft <= 0) {
      alert("OTP has expired. Please request a new one.");
      return;
    }

    try {
      // Verify OTP via API
      await verifyOtpApi(inputValues.email, enteredOtp);
      // If successful, move to password reset page
      setCurrentPopup("reset");
    } catch (error) {
      // Show error or set to an error state
      alert(error.message || "OTP verification failed.");
    }
  };

  // ------------------------------------
  // 3) RESET PASSWORD
  // ------------------------------------
  const handleResetPassword = async (event) => {
    event.preventDefault();
    const { newPassword, confirmPassword, email } = inputValues;

    // Basic validation
    if (newPassword !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match." });
      return;
    }

    try {
      // Update user password
      await updatePassword(email, newPassword);

      // Move to success screen
      setCurrentPopup("verify");
    } catch (error) {
      // Handle error
      setErrors({ confirmPassword: error.message || "Password update failed." });
    }
  };

  const handleCrossIconClick = () => {
    onClose();
  };

  // ------------------------------------
  // RENDER UI
  // ------------------------------------
  return (
    <>
      {currentPopup === "forgetpop" ? (
        // Outer container for "forgetpop" with click-outside-to-close behavior
        <div className={styles.overlayContainer} onClick={handleCrossIconClick}>
          <div className={styles["blur-bg"]}></div>
          {/* The loginpopup container stops propagation so that clicks inside do not close it */}
          <div className={styles.loginpopup} onClick={(e) => e.stopPropagation()}>
            {/* Cross (Close) Icon rendered only for "forgetpop" */}
            <div className={styles.popupclose}>
              <img
                className={styles.close_icon}
                src={Cross_icon}
                onClick={handleCrossIconClick}
                alt="cross_icon"
              />
            </div>
            <div className={styles.signpop}>
              <h1>Reset Password</h1>
              <p>Enter your email to receive reset instructions.</p>
              <form onSubmit={handleSubmitEmail}>
                <div
                  className={`${styles["form-div"]} ${
                    activeField === "email" ? styles["active"] : ""
                  }`}
                >
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    className={styles["input-validate"]}
                    id="email"
                    onFocus={() => handleFocus("email")}
                    onChange={handleChange}
                    onBlur={() => handleBlur("email")}
                    value={inputValues.email}
                  />
                  {errors.email && (
                    <span className={styles["error-div"]}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="11"
                        viewBox="0 0 10 10"
                      >
                        <path
                          d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148Z"
                          transform="translate(-9.921 -9.92)"
                          fill="#e02b27"
                          fillRule="evenodd"
                        />
                      </svg>
                      {errors.email}
                    </span>
                  )}
                </div>
                <button type="submit" className={styles.signup_btn} disabled={isforgetting}>
                  {isforgetting ? "Sending OTP..." : "Send OTP"}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* For OTP, reset, and verification screens */}
          <div className={styles["blur-bg"]}></div>
          <div className={styles.loginpopup}>
            {currentPopup === "otp" && (
              <div className={styles.otp}>
                <h1>OTP Verification</h1>
                <p>
                  We have sent a One Time Password (OTP) to your email
                  <br />
                  Enter 4-digit code for verification.
                </p>
                <form>
                  <span id="timer" className={styles.timer}>
                    {timeLeft > 0
                      ? `(${Math.floor(timeLeft / 60)}:${String(timeLeft % 60).padStart(
                          2,
                          "0"
                        )})`
                      : "Code expired"}
                  </span>
                  <div className={styles["otp-input"]}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        ref={(el) => (inputsRef.current[index] = el)}
                        disabled={timeLeft === 0}
                      />
                    ))}
                  </div>
                  <div className={styles["resend-text"]}>
                    Don&apos;t receive the OTP?{" "}
                    <span
                      className={styles["resend-link"]}
                      onClick={resendOtp}
                      style={{
                        cursor: canResend ? "pointer" : "not-allowed",
                        color: canResend ? "blue" : "gray",
                      }}
                    >
                      Resend
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={verifyOtp}
                    className={styles.otpsubmitbtn}
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
    
            {currentPopup === "reset" && (
              <div className={styles.signpop}>
                <h1>Set New Password</h1>
                <form onSubmit={handleResetPassword}>
                  <div
                    className={`${styles["form-div"]} ${
                      activeField === "newPassword" ? styles["active"] : ""
                    }`}
                  >
                    <label htmlFor="newPassword">New Password*</label>
                    <input
                      type="password"
                      name="newPassword"
                      className={styles["input-validate"]}
                      id="newPassword"
                      value={inputValues.newPassword}
                      onChange={handleChange}
                      onFocus={() => handleFocus("newPassword")}
                      onBlur={() => handleBlur("newPassword")}
                      required
                    />
                  </div>
                  <div
                    className={`${styles["form-div"]} ${
                      activeField === "confirmPassword" ? styles["active"] : ""
                    }`}
                  >
                    <label htmlFor="confirmPassword">Confirm Password*</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className={styles["input-validate"]}
                      id="confirmPassword"
                      value={inputValues.confirmPassword}
                      onChange={handleChange}
                      onFocus={() => handleFocus("confirmPassword")}
                      onBlur={() => handleBlur("confirmPassword")}
                      required
                    />
                    {errors.confirmPassword && (
                      <div className={styles["error-message"]}>
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                  <button type="submit" className={styles.signup_btn}>
                    Reset Password
                  </button>
                </form>
              </div>
            )}
    
            {currentPopup === "verify" && (
              <div className={styles.verifySuccess}>
                <img src={verify} alt="Verify" />
                <h1>Verification Successful</h1>
                <p>Your password has been reset successfully.</p>
                <p>Login to Explore Chatmro</p>
                <button className={styles.signup_btn} onClick={onLogin}>
                  Return to Login
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default ForgetPassword; 