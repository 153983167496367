import axios from "axios";

const url = process.env.REACT_APP_BACKEND_BASE_URL;

// Fetch visitor session details
export const getVisitorSessions = async (visitorId , email) => {
  try {
    const response = await axios.get(`${url}/get_visitor_sessions`, {
      params: { visitor_id: visitorId ,
        email: email
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching visitor sessions:", error);
    return null;
  }
};

// Function to increment the message counter for a given session
export const incrementMessageCounter = async ({ sessionId, email, fingerprint, version }) => {
  try {
    const response = await axios.post( url +'/incrementMessageCounter', {
      sessionId,
      email,
      fingerprint,
      version
    });
    return response;
  } catch (error) {
    console.error('Error incrementing message counter:', error);
    throw error;
  }
};
