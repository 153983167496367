import React, { useState , useRef , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Google_icon from "../assets/icons/svg/google.svg";
import Eye_icon from "../assets/icons/svg/eye-icon.svg";
import Cross_icon from "../assets/icons/svg/cross-icon.svg";
import Hide_eye_icon from "../assets/icons/svg/hide-icon.svg";
import styles from '../Css/login.module.css';
import { loginUser } from "../service/TokenCreate";
import Cookies from 'js-cookie';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { createUser, verifyOtp } from '../service/createuser_Email';

const Loginbox = ({ onClose, onPasswordSubmit, onSignup, onForget }) => {


  const navigation = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [remainingAttempts, setRemainingAttempts] = useState(5); // Track remaining attempts
  const storedClientId = process.env.REACT_APP_SECRET_GOOGLE_AUTH_ID;

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const handleBlur = (field) => {
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSuccess = (response) => {
    const userObject = jwt_decode(response.credential);
    console.log(userObject)
    if (userObject && userObject.email) {
      handlePasswordSubmitInternal(userObject.email);
      try{
        let password = ""
        let auth_provider ='Google'
        const result = createUser(userObject.email, password, userObject.email_verified, auth_provider);
        localStorage.setItem('user_name' , userObject.given_name)
        if (result.status !== 200) {
          throw new Error(`Signup failed: ${result.detail || "Unexpected error"}`);
        }
      }
      catch{
        console.log('Unable to save')
      }
    }
  };

  const handleCrossIconClick = () => {
    onClose();
  };

  const handleGoogleError = () => {
    console.error("Google Login Failed");
  };

  const handlePasswordSubmitInternal = (email) => {
    localStorage.setItem("email", email);
    onPasswordSubmit(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoggingIn(true);

    try {
      const access_token = await loginUser(inputValues.email, inputValues.password);
      if (access_token) {
        Cookies.set('authToken', access_token, { expires: 7 });
        Cookies.set('email', inputValues.email, { expires: 7 });
        localStorage.setItem('email', inputValues.email);
        onPasswordSubmit(inputValues.email);
        navigation('/');
        localStorage.setItem('loginAttempts', '0'); // Reset login attempts on successful login
      } else {
        setError('Invalid email or password');
        // Update the number of failed attempts and timestamp
        // localStorage.setItem('loginAttempts', (attempts + 1).toString());
        // localStorage.setItem('lastAttemptTime', currentTime.toString());
        // setRemainingAttempts(5 - (attempts + 1)); // Update remaining attempts
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred during login.');
    } finally {
      setIsLoggingIn(false); // Reset logging in state after login attempt
    }
  };

  return (
    <div 
      className={styles.overlay} 
      onClick={handleCrossIconClick} // or onClose prop if available
    >
      <div className={styles.blurBg}></div>
      <div 
        className={styles.loginpopup} 
        onClick={(e) => e.stopPropagation()}  // Prevent click events inside the popup from closing it
      >
        <div className={styles.popupclose}>
          <img 
            className={styles.close_icon} 
            src={Cross_icon} 
            onClick={handleCrossIconClick} 
            alt="cross_icon" 
          />
        </div>
        <h1>Login</h1>
        <p>Login to unlock comprehensive MRO solutions!</p>
        <form>
          <div className={`${styles['form-div']} ${activeField === 'email' || inputValues.email ? styles.active : ''} ${error && styles.error}`}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              name="email"
              className={styles['input-validate']}
              id="email"
              required
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
              onChange={handleChange}
              value={inputValues.email}
            />
            {error && (
              <span className={styles['error-div']}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 10 10">
                  <path id="Path_4483" data-name="Path 4483" d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148ZM14.7,12.358c.294,0,.7-.071.7.253v3.228c0,.324-.4.253-.7.253a.253.253,0,0,1-.253-.253V12.611a.253.253,0,0,1,.253-.253Zm3.757,6.1a5,5,0,1,1,0-7.071A5,5,0,0,1,18.456,18.456Z" transform="translate(-9.921 -9.92)" fill="#e02b27" fill-rule="evenodd"/>
                </svg>
                Enter valid email address and Password to login
              </span>
            )}
          </div>
          <div className={`${styles['form-div']} ${activeField === 'password' || inputValues.password ? styles.active : ''}`}>
            <label htmlFor="password">Password*</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              className={styles['input-validate']}
              id="password"
              onFocus={() => handleFocus('password')}
              onBlur={() => handleBlur('password')}
              onChange={handleChange}
              value={inputValues.password}
              autoComplete="new-password"
            />
            <button type="button" onClick={togglePasswordVisibility} className={styles.eye_button}>
              <img src={showPassword ? Eye_icon : Hide_eye_icon} alt="eye_icon" />
            </button>
          </div>
          <div className={styles['forgot-ps']} onClick={onForget}>
            Forgot Password?
          </div>
          <button
            className={styles.login_btn}
            type="submit"
            onClick={handleLogin}
            disabled={isLoggingIn}
          >
            {isLoggingIn ? "Logging in, please wait..." : "Log In"}
          </button>
        </form>
        <p className={styles['or-signup']}>
          <span>or Sign up with</span>
        </p>
        <GoogleOAuthProvider clientId={storedClientId} className={styles['yamini-s']}>
          <GoogleLogin 
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleError}
            text=""
            theme="outline"
            render={(renderProps) => (
              <button
                type="button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className={styles['continue-google']}
              >
                <img
                  className={styles.google_icon}
                  src={Google_icon}
                  alt="Google Icon"
                />
                Continue with Google
              </button>
            )}
          />
        </GoogleOAuthProvider>
        <p className={styles['already-account']}>
          Don't have an account?{" "}
          <a 
            onClick={onSignup} 
            style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          >
            Sign Up
          </a>
        </p>
      </div>
    </div>
  );
};  
export default Loginbox;
