import React, { forwardRef, useState, useEffect, useRef } from "react";
import Submit from "../assets/icons/svg/submit.svg";
import '../Css/bottomsearchbar.css';

const BottomSearchBar = forwardRef(({ 
  prompt, 
  placeholder = "Ask Anything...", 
  onChange = () => {}, 
  styles, 
  handleSubmit = () => {}, 
  isFetchingAnswer,
  className = ""
}, ref) => {
  const textareaRef = useRef(null);
  const [height, setHeight] = useState(25); // Initial height
  const maxHeight = 120; // Maximum height before scrolling

  // Function to adjust height dynamically
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "25px"; // Reset height to measure actual scrollHeight
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight(); // Adjust height when the component mounts or updates
  }, [prompt]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit();
      e.preventDefault(); // Prevents new line on Enter
    }
  };

  return (
    <div className={`search-line ${className}`}> 
      <div className="text">
        <textarea
          ref={(el) => {
            textareaRef.current = el;
            if (ref) ref.current = el;
          }}
          className="small-box"
          placeholder={placeholder}
          style={{ 
            ...styles, 
           
           
            overflowY: textareaRef.current && textareaRef.current.scrollHeight > maxHeight ? "scroll" : "hidden",
            resize: "none" // Disables manual resizing
          }}
          value={isFetchingAnswer ? "" : prompt}
          onChange={(e) => {
            onChange(e);
            adjustHeight();
          }}
          onKeyDown={handleKeyDown}
          disabled={isFetchingAnswer}
        />
        <div className="submit-btn" onClick={handleSubmit}> 
          <img src={Submit} alt="Submit button icon" />
        </div>
      </div>
      <label className="mistake-label">
        ChatMRO can make mistakes. Check important info.
      </label>
    </div>
  );
});

export default BottomSearchBar;
