import React, { useState, useEffect, useRef } from "react";
import Google_icon from "../assets/icons/svg/google.svg";
import Eye_icon from "../assets/icons/svg/eye-icon.svg";
import error_icon from "../assets/icons/svg/error.svg";
import Hide_eye_icon from "../assets/icons/svg/hide-icon.svg";
import Cross_icon from "../assets/icons/svg/cross-icon.svg";
import styles from './signup.module.css';
import verify from '../assets/icons/svg/Verifylogo.svg';
import { useNavigate } from 'react-router-dom';
import { createUser, verifyOtp } from '../service/createuser_Email';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode as jwt_decode } from "jwt-decode";

const Signupbox = ({ onClose, onLogin }) => {
  const navigate = useNavigate();
  const [activeField, setActiveField] = useState(null);
  const [inputValues, setInputValues] = useState({ email: '', password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [canResend, setCanResend] = useState(true);
  const [errors, setErrors] = useState({});
  const inputsRef = useRef([]);
  const storedClientId = process.env.REACT_APP_SECRET_GOOGLE_AUTH_ID;
  const [currentPopup, setCurrentPopup] = useState("signpop"); // Manage current popup
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    new_password: false,
    confirm_new_password: false,
  });

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timerId);
    } else {
      setCanResend(false);
    }
  }, [timeLeft]);

  const handleFocus = (field) => {
    setActiveField(field);
  };

  const handleBlur = (field) => {
    if (!inputValues[field]) {
      setActiveField(null);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null }); // Clear errors when user corrects them
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateInputs = () => {
    let newErrors = {};
    if (!inputValues.email.trim()) {
      newErrors.email = "Email is required.";
    }
    if (!inputValues.password.trim()) {
      newErrors.password = "Password is required.";
    }
    if (inputValues.password !== inputValues.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if no errors
  };

  const handleSignup = async (e) => {
    e.preventDefault(); 
    if (validateInputs()) {
      setIsSigningUp(true); // Start the signup process
      try {
        const result = await createUser(inputValues.email, inputValues.password);
        if (result.status !== 200) {
          throw new Error(`Signup failed: ${result.detail || "Unexpected error"}`);
        }
        setCurrentPopup("otp"); // Move to OTP input on successful user creation
      } catch (error) {
        setIsSigningUp(false); // Reset the signup state so user can try again
        setErrors({ email: error.message }); // Store error message
      }
    }
  };

  const handleOtpChange = (value, index) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const resendOtp = () => {
    setCanResend(true)
    if (canResend) {
      alert("New OTP sent!");
      setOtp(["", "", "", ""]);
      setTimeLeft(120);
      setCanResend(true);
      inputsRef.current[0]?.focus();
    } else {
      alert("Cannot resend OTP. Please re-click on resend .");
    }
  };

  const verifyUserOtp = async () => {
    try {
      const result = await verifyOtp(inputValues.email, otp.join(''));
      alert("Your account has been verified!");
      setCurrentPopup('verify') // Provide feedback to user
    } catch (error) {
      console.error('OTP verification failed:', error.message);
      setErrors({ otp: error.message });
    }
  };

  const handleVerifyOtp = () => {
    if (otp.join('').length === 4 && timeLeft > 0) {
      verifyUserOtp();
    } else {
      alert("Please check the OTP and try again or OTP has expired.");
    }
  };

  const navigatetologin = () => {
    onLogin();
  };
 const handleGoogleSuccess = (response) => {
    const userObject = jwt_decode(response.credential);
    console.log(userObject)
    if (userObject && userObject.email) {
      handlePasswordSubmitInternal(userObject.email);
      try{
        let password = ""
        let auth_provider ='Google'
        const result = createUser(userObject.email, password, userObject.email_verified, auth_provider);
        localStorage.setItem('user_name' , userObject.given_name)
        if (result.status !== 200) {
          throw new Error(`Signup failed: ${result.detail || "Unexpected error"}`);
        }
      }
      catch{
        console.log('Unable to save')
      }
    }
  };

  const handleGoogleError = () => {
    console.error("Google Login Failed");
  };

  const handlePasswordSubmitInternal = (email) => {
    localStorage.setItem("email", email);
    onClose(); // Redirect to the homepage
  };

  const handleCrossIconClick = () => {
    onClose();
  };

  const handlelogin = () => {
    onLogin();
  };

  return (
    <>
      {currentPopup === "signpop" ? (
        // Outer container that covers the full screen for click-outside behavior
        <div className={styles.overlayContainer} onClick={handleCrossIconClick}>
          <div className={styles["blur-bg"]}></div>
          {/* The loginpopup container stops propagation so that clicks inside do not close it */}
          <div className={styles.loginpopup} onClick={(e) => e.stopPropagation()}>
            <div className={styles.popupclose}>
              <img
                className={styles.close_icon}
                src={Cross_icon}
                onClick={handleCrossIconClick}
                alt="cross_icon"
              />
            </div>
            <div className={styles.signpop}>
              <h1>Sign Up</h1>
              <p>Sign up to unlock comprehensive MRO solutions!</p>
              <form>
                <div
                  className={`${styles["form-div"]} ${
                    activeField === "email" || inputValues.email ? styles.active : ""
                  }`}
                >
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    className={styles["input-validate"]}
                    id="email"
                    required
                    onFocus={() => handleFocus("email")}
                    onBlur={() => handleBlur("email")}
                    onChange={handleChange}
                    value={inputValues.email}
                  />
                  {errors.email && (
                    <span className={styles["error-div"]}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="11"
                        viewBox="0 0 10 10"
                      >
                        <path
                          d="M14.921,19.414a4.494,4.494,0,1,0-4.494-4.494,4.494,4.494,0,0,0,4.494,4.494Zm0-2.944a.507.507,0,0,1,.358.864h0a.506.506,0,0,1-.715-.715h0a.5.5,0,0,1,.358-.148Z"
                          transform="translate(-9.921 -9.92)"
                          fill="#e02b27"
                          fillRule="evenodd"
                        />
                      </svg>
                      Enter valid email address
                    </span>
                  )}
                </div>
  
                <div
                  className={`${styles["form-div"]} ${
                    activeField === "password" || inputValues.password ? styles.active : ""
                  }`}
                >
                  <label htmlFor="password">Password*</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className={styles["input-validate"]}
                    id="password"
                    required
                    onFocus={() => handleFocus("password")}
                    onBlur={() => handleBlur("password")}
                    onChange={handleChange}
                    value={inputValues.password}
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className={styles.eye_button}
                  >
                    <img
                      src={showPassword ? Eye_icon : Hide_eye_icon}
                      alt="eye_icon"
                    />
                  </button>
                  {errors.password && (
                    <span className={styles["error-div"]}>
                      Password must meet requirements.
                    </span>
                  )}
                </div>
  
                <div
                  className={`${styles["form-div"]} ${
                    activeField === "confirmPassword" ||
                    inputValues.confirmPassword
                      ? styles.active
                      : ""
                  }`}
                >
                  <label htmlFor="confirmPassword">Confirm Password*</label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    className={styles["input-validate"]}
                    id="confirmPassword"
                    required
                    onFocus={() => handleFocus("confirmPassword")}
                    onBlur={() => handleBlur("confirmPassword")}
                    onChange={handleChange}
                    value={inputValues.confirmPassword}
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className={styles.eye_button}
                  >
                    <img
                      src={showConfirmPassword ? Eye_icon : Hide_eye_icon}
                      alt="eye_icon"
                    />
                  </button>
                  {errors.confirmPassword && (
                    <span className={styles["error-div"]}>
                      <img
                        className={styles.google_icon}
                        src={error_icon}
                        alt="google_icon"
                      />
                      Passwords do not match.
                    </span>
                  )}
                </div>
  
                <button
                  className={styles.signup_btn}
                  onClick={handleSignup}
                  disabled={isSigningUp}
                >
                  {isSigningUp ? "Signing up..." : "Sign Up"}
                </button>
              </form>
              <p className={styles["or-signup"]}>
                <span>or Sign up with</span>
              </p>
              <button className={styles["continue-google"]}>
                <span>
                  <GoogleOAuthProvider clientId={storedClientId}>
                    <GoogleLogin
                      onSuccess={handleGoogleSuccess}
                      onError={handleGoogleError}
                      render={(renderProps) => (
                        <button
                          type="button"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          className={styles["continue-google"]}
                        >
                          <img
                            className={styles.google_icon}
                            src={Google_icon}
                            alt="Google Icon"
                          />
                          Continue with Google
                        </button>
                      )}
                    />
                  </GoogleOAuthProvider>
                </span>
                <img
                  className={styles.google_icon}
                  src={Google_icon}
                  alt="Google Icon"
                />
                Continue with Google
              </button>
              <p className={styles["already-account"]}>
                Already have an account?{" "}
                <a
                  onClick={handlelogin}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Render OTP or Verify screens without click-outside-to-close */}
          <div className={styles["blur-bg"]}></div>
          <div className={styles.loginpopup}>
            {currentPopup === "otp" && (
              <div className={styles.otp}>
                <h1>OTP Verification</h1>
                <p>
                  We have sent a One Time Password (OTP) to your email
                  <br />
                  Enter 4-digit code for verification.
                </p>
                <form>
                  <span id="timer" className={styles.timer}>
                    {timeLeft > 0
                      ? `(${Math.floor(timeLeft / 60)}:${String(
                          timeLeft % 60
                        ).padStart(2, "0")})`
                      : "Code expired"}
                  </span>
                  <div className={styles["otp-input"]}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        ref={(el) => (inputsRef.current[index] = el)}
                        disabled={timeLeft === 0}
                      />
                    ))}
                  </div>
                  <div className={styles["resend-text"]}>
                    Don't receive the OTP?{" "}
                    <span
                      className={styles["resend-link"]}
                      onClick={resendOtp}
                      style={{
                        cursor: canResend ? "pointer" : "not-allowed",
                        color: canResend ? "blue" : "gray",
                      }}
                    >
                      Resend
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={handleVerifyOtp}
                    className={styles.otpsubmitbtn}
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
  
            {currentPopup === "verify" && (
              <div className={styles.verifySuccess}>
                <img src={verify} alt="Verify" />
                <h1>Verification Successful</h1>
                <p>Your account has been successfully created.</p>
                <p>Login to explore ChatMRO</p>
                <button
                  className={styles.signup_btn}
                  onClick={navigatetologin}
                >
                  Return to Login
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};  
export default Signupbox;
