// components/RecentChats.jsx

import React, { useEffect, useState } from "react";
import {
  fetchRecentChats,
  fetchRecentChatsconveration,
  fetchLatestChatTitle
} from "../service/fetchRecentChats";
import dayjs from "dayjs";
import "../Css/recentchat.css";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";


function RecentChats({ email }) {
  const [recentChats, setRecentChats] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);  
  // For optionally truncating long titles
  const truncateText = (text = "", maxLength = 90) =>
    text.length > maxLength ? text.slice(0, maxLength) + "..." : text;

  const generateSessionId = () => {
    const timestamp = Date.now();
    const uuid = uuidv4().substring(0, 8);;
    return `${timestamp}_${uuid}`;
  };
  // Function to fetch recent chats
  const fetchChats = (showLoader = true) => {
    localStorage.setItem("newChatTriggered", "false");
    if (email === "guest@gmail.com") {
      // If guest user, skip fetch
      setRecentChats({});
      return Promise.resolve();
    }

    if (showLoader) {
      setLoading(true);
    }
    
    return fetchRecentChats(email)
      .then((data) => {
        // Data is expected to be an object:
        // { sessionId1: ["Title1", "2025-01-28T09:00:00Z"], sessionId2: [...], ... }
        setRecentChats(data || {});
        if (showLoader) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch recent chats:", error);
        if (showLoader) {
          setLoading(false);
        }
      });
  };

  // 1) Fetch the list of recent chats on mount (unless guest)
  useEffect(() => {
    fetchChats();
  }, [email]);

  // State to track new chat animations
  const [newChatId, setNewChatId] = useState(null);

  // Keep track of empty "New Chat" entries
  const [emptyNewChatId, setEmptyNewChatId] = useState(null);
  
  // State for tracking title update animations
  const [titleUpdateId, setTitleUpdateId] = useState(null);

  // Listen for new chat events to refresh the chat list
  useEffect(() => {
    // Handler for the saved chat event - when a message is sent/received
    const handleNewChat = (e) => {
      const chatPayload = e.detail;
      
      if (chatPayload && chatPayload.isNewChat && chatPayload.sessionId && chatPayload.title) {
        // For new chats, directly update the state without making an API call
        setRecentChats(prevChats => {
          // Create entry format expected by the component
          const newChatEntry = {
            [chatPayload.sessionId]: [chatPayload.title, chatPayload.timestamp]
          };
          
          // Set animation flag for this new chat
          setNewChatId(chatPayload.sessionId);
          
          // Clear animation after animation completes
          setTimeout(() => {
            setNewChatId(null);
          }, 1500);
          
          // Remove any empty "New Chat" entries
          const updatedChats = {...prevChats};
          if (emptyNewChatId && updatedChats[emptyNewChatId]) {
            delete updatedChats[emptyNewChatId];
            setEmptyNewChatId(null);
          }
          
          // Return updated chats with the new one at the top
          return { ...newChatEntry, ...updatedChats };
        });
      } else if (chatPayload && chatPayload.sessionId) {
        // For existing chats, do a silent refresh without showing loader
        setTimeout(() => {
          fetchChats(false);
        }, 300);
      }
    };

    // Handler for new chat button - adds a "New Chat" entry before any message is sent
    const handleNewChatButton = (e) => {
      const newChatData = e.detail;
      
      if (newChatData && newChatData.sessionId && newChatData.isEmptyChat) {
        // If we already have an empty "New Chat" entry, remove it
        localStorage.setItem("newChatTriggered", "true");
        setRecentChats(prevChats => {
          const updatedChats = {...prevChats};
          
          // Delete previous empty chat if exists
          if (emptyNewChatId && updatedChats[emptyNewChatId]) {
            delete updatedChats[emptyNewChatId];
          }
          
          // Store this new empty chat ID
          setEmptyNewChatId(newChatData.sessionId);
          setNewChatId(newChatData.sessionId);
          
          // Add the new empty chat at the top
          const newEntry = {
            [newChatData.sessionId]: [newChatData.title, newChatData.timestamp]
          };
          
          // Clear animation after animation completes
          setTimeout(() => {
            setNewChatId(null);
          }, 1500);
          
          return { ...newEntry, ...updatedChats };
        });
      }
    };

    // Add event listeners
    window.addEventListener('newChatSaved', handleNewChat);
    window.addEventListener('newChatButton', handleNewChatButton);

    // Clean up on unmount
    return () => {
      window.removeEventListener('newChatSaved', handleNewChat);
      window.removeEventListener('newChatButton', handleNewChatButton);
    };
  }, [email, emptyNewChatId]);
  
  // Handle bot response events to update chat titles
  useEffect(() => {
    const handleBotResponse = async (e) => {
      const responseData = e.detail;
      
      if (!responseData || !responseData.sessionId) return;
      
      // Check if this is for the "New Chat" entry or any active chat
      const isActiveChat = responseData.sessionId === localStorage.getItem("sessionId");
      const isEmptyNewChatSession = responseData.sessionId === emptyNewChatId;
      
      if (isActiveChat || isEmptyNewChatSession) {
        try {
          // Fetch the latest title for this chat
          const titleInfo = await fetchLatestChatTitle(responseData.sessionId);
          
          if (titleInfo && titleInfo.title) {
            // Update only this specific chat's title without animation
            setRecentChats(prevChats => {
              // Clone the current chats
              const updatedChats = {...prevChats};
              
              // If we have this chat, update its title
              if (updatedChats[responseData.sessionId]) {
                // Keep the existing timestamp but update the title
                const timestamp = updatedChats[responseData.sessionId][1] || titleInfo.timestamp;
                updatedChats[responseData.sessionId] = [titleInfo.title, timestamp];
                
                // Set the animation for title update
                setTitleUpdateId(responseData.sessionId);
                
                // Clear animation after it completes
                setTimeout(() => {
                  setTitleUpdateId(null);
                }, 1500);
                
                // If this was an empty new chat, it's not empty anymore
                if (isEmptyNewChatSession) {
                  setEmptyNewChatId(null);
                }
              }
              
              return updatedChats;
            });
          }
        } catch (error) {
          console.error("Error updating chat title after bot response:", error);
        }
      }
    };
    
    // Add event listener
    window.addEventListener('botResponseReceived', handleBotResponse);
    
    // Clean up
    return () => {
      window.removeEventListener('botResponseReceived', handleBotResponse);
    };
  }, [emptyNewChatId]);

  // Function to create a new chat (like clicking the New Chat button)
  const createNewChat = () => {
    // Clear conversation in localStorage
    localStorage.setItem("conversation", JSON.stringify([]));
    
    // Generate new session ID - use the same function the sidebar uses
    // This assumes you have access to generateSessionId from props
    // If not, you might need to add it to the component props
    const sessionId =  generateSessionId();
    localStorage.setItem("sessionId", sessionId);
    
    // Create payload for the new chat event
    const newChatPayload = {
      sessionId: sessionId,
      email: email || localStorage.getItem("email") || "guest@gmail.com",
      title: "New Chat",
      timestamp: new Date().toISOString(),
      isEmptyChat: true
    };
    
    // Dispatch the same event as the New Chat button
    window.dispatchEvent(new CustomEvent('newChatButton', { 
      detail: newChatPayload 
    }));
    
    // Set selected session to this new one
    setSelectedSessionId(sessionId);
  };

  // 2) When user clicks a chat item, fetch entire conversation & convert it
  const handleChatClick = async (sessionId, title) => {
    // Check if this is an empty "New Chat" entry
    if (sessionId === emptyNewChatId) {
      // If so, don't fetch conversation - just create a new chat
      createNewChat();
      return;
    }
    
    // Otherwise proceed with regular chat selection
    setSelectedSessionId(sessionId);

    try {
      // 2A) Fetch the full conversation from your API
      const result = await fetchRecentChatsconveration(sessionId);
      // Expect result = { session_id, messages: [ {sender, msg, timestamp}, ... ] }

      // 2B) Convert old format -> new format for <AnswerBox />
      //     "User" => { user: "User", prompt: message }
      //     "Assistant" => { user: "ChatMRO", answer: message }
      const convertedConversation = result.messages.map((m) => {
        if (m.sender === "User") {
          return {
            user: "User",
            prompt: m.msg,
          };
        } else {
          // e.g., "Assistant" or "ChatGPT"
          return {
            user: "ChatMRO",
            answer: m.msg,
          };
        }
      });

      // 2C) Store in localStorage for Chat.jsx to load
      localStorage.setItem(
        "conversation",
        JSON.stringify(convertedConversation)
      );
      localStorage.setItem("sessionId", result.session_id);
    } catch (err) {
      console.error(
        `Failed to fetch conversation for sessionId=${sessionId}: `,
        err
      );
    }
  };

  // If we're loading the list, show a spinner or placeholder
  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  

  // If no chats or guest user
  const sessionIds = Object.keys(recentChats);
  if (sessionIds.length === 0) {
    return <div className="no-chat-tag">No chats present</div>;
  }

  // 3) Separate chats into Today vs. Previous
  const today = dayjs().startOf("day");
  const todayChats = [];  
  const previousChats = [];

  sessionIds.forEach((sessionId) => {
    const chat = recentChats[sessionId]; // e.g. [title, createdTime]
    if (!Array.isArray(chat) || chat.length < 2) return; // skip malformed

    const [title, createdTime] = chat;
    const chatDate = dayjs(createdTime);

    if (chatDate.isAfter(today)) {
      todayChats.push({ sessionId, title, createdAt: createdTime });
    } else {
      previousChats.push({ sessionId, title, createdAt: createdTime });
    }
  });

  // 4) Sort them by created time DESC
  todayChats.sort(
    (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
  );
  previousChats.sort(
    (a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
  );

  return (
    <div className="recent-chats">
      {/* <h5>Recent Chats</h5> */}      
      {/* Today Chats */}
      {todayChats.length > 0 && (
        <>
          <p className="today">Today</p>
          <ul>
            {todayChats.map(({ sessionId, title }) => (
              <li
                key={sessionId}
                onClick={() => handleChatClick(sessionId, title)}
                className={`
                  ${sessionId === selectedSessionId ? "active" : ""} 
                  ${newChatId === sessionId ? "new-chat-item" : ""}
                  ${sessionId === emptyNewChatId ? "empty-new-chat" : ""}
                  ${sessionId === titleUpdateId ? "title-update" : ""}
                `}
              >
                {truncateText(title ?? "Untitled Chat", 100)}
              </li>
            ))}
          </ul>
        </>
      )}

      {/* Previous Chats */}
      {previousChats.length > 0 && (
        <>
          <p className="previous">Previous Chats</p>
          <ul>
            {previousChats.map(({ sessionId, title }) => (
              <li
                key={sessionId}
                onClick={() => handleChatClick(sessionId, title)}
                className={`
                  ${sessionId === selectedSessionId ? "active" : ""} 
                  ${newChatId === sessionId ? "new-chat-item" : ""}
                  ${sessionId === emptyNewChatId ? "empty-new-chat" : ""}
                  ${sessionId === titleUpdateId ? "title-update" : ""}
                `}
              >
                {truncateText(title ?? "Untitled Chat", 60)}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default RecentChats;
