import React, { useState, useEffect } from "react";
import "../Css/CustomAlert.css"; // Add styles for alert

const CustomAlert = () => {
  const [message, setMessage] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleAlert = (e) => {
      setMessage(e.detail);
      setVisible(true);
      setTimeout(() => setVisible(false), 5000); // Auto-hide after 5 sec
    };

    window.addEventListener("customAlert", handleAlert);
    return () => window.removeEventListener("customAlert", handleAlert);
  }, []);

  return (
    <div className={`custom-alert ${visible ? "show" : ""}`}>
      {message}
    </div>
  );
};

export default CustomAlert;
