export async function updateGuestChatsToLoggedInUser(sessionId, newEmail) {
    
    const url = process.env.REACT_APP_BACKEND_BASE_URL
    if (newEmail !== 'guest@user.com') {
      try {            
          await fetch( url + '/updateChats', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId, email: newEmail }),
          });
        }
      // } 
      catch (error) {
        console.error('Error updating guest chats:', error);
      }
    }
  }