import React, { useState } from "react";
import "../Css/userprofile.css";
import { handleStorageChange } from "./storageHandler";

const UserProfile = () => {
  // State to handle the confirmation dialog visibility
  const [showConfirm, setShowConfirm] = useState(false);

  // Helper to delete cookies (no change needed here)
  const deleteCookie = (name) => {
    document.cookie = `${name}=; Max-Age=0; path=/; domain=${window.location.hostname};`;
  };

  // Retrieve the email from localStorage
  const email = localStorage.getItem("email") || "";

  // Extract the portion before '@'
  const username = email.split("@")[0];
   // Determine the initial: use localStorage's "user_name" if it exists, otherwise use the first character of the username
   let username1 = "";
   if (localStorage.getItem("user_name")) {
     username1= localStorage.getItem("user_name");
   } else {
     username1 = username 
   }
  

  // Get the first character of the username, capitalized
  const initial = username ? username.charAt(0).toUpperCase() : "";

  // Handle user logout
  const handleLogout = () => {
    localStorage.clear();
    // console.log("Logging out...");
    deleteCookie("authToken");
    deleteCookie("ACCOUNT_CHOOSER");
    window.location.reload();
  };

  // Show confirmation dialog
  const handleShowConfirm = () => {
    setShowConfirm(true);
  };

  // Hide confirmation dialog
  const handleHideConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <div>
      <div className="user-profile" onClick={handleShowConfirm} style={{ cursor: "pointer" }}>
        {/* Profile initial/logo */}
        <div className="profile-initial">{initial}</div>
        {/* Username (everything before @) */}
        <p>{
        username1}</p>
      </div>

      {/* Confirmation dialog */}
      {showConfirm && (
        <div className="confirmation-dialog">
          <p>Are you sure you want to logout?</p>
          <button onClick={handleLogout}>Yes</button>
          <button onClick={handleHideConfirm}>No</button>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
