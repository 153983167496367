import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import Chat from "./chat";
import OverlayMessage from "./overlay"; // or OverlayMessage
import SessionErrorBox from "./AlertBox";
import { useNavigate } from 'react-router-dom';
import "../Css/Mainpage.css";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import TagManager from "react-gtm-module";
const { updateGuestChatsToLoggedInUser } = require("../service/update");

const MainPage = () => {
  // ------------------------------------------
  // STATE
  // ------------------------------------------
  const [showOverlay, setShowOverlay] = useState(false);
  
  // NEW: Which view to show in the overlay? "login", "signup", or "forget"
  const [overlayView, setOverlayView] = useState(null);

  const inputRef = React.createRef();
  const navigate = useNavigate();
  const [loginHandle, setLoginHandle] = useState(false);
  const [visitorId, setVisitorId] = useState(null);
  const [showSessionError, setShowSessionError] = useState(false);
  const [email, setEmail] = useState("guest@gmail.com");
  
  const generateSessionId = () => {
      const timestamp = Date.now();
      const uuid = uuidv4().substring(0, 8);;
      return `${timestamp}_${uuid}`;
    };
  const [sessionId, setSessionId] = useState(localStorage.getItem("sessionId") || generateSessionId());

  // Helper for cookies
  const getCookie = (name) => Cookies.get(name);

  // Helper: show an alert after successful login
  const displayAlert = () => {
    alert("Login successful!");
  };
  // ------------------------------------------
  // USEEFFECTS
  // ------------------------------------------
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NHDVFHVW" });
  }, []);
  
  useEffect(() => {
    // 1) Ensure `login_handle` in localStorage
    if (localStorage.getItem("login_handle") === null) {
      localStorage.setItem("login_handle", "false");
      setLoginHandle(false);
    } else {
      setLoginHandle(localStorage.getItem("login_handle") === "true");
    }

    // 2) Ensure `email` in localStorage
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    } else {
      localStorage.setItem("email", "guest@gmail.com");
      setEmail("guest@gmail.com");
    }

    // 3) Ensure `sessionId`
    if (!localStorage.getItem("sessionId") || localStorage.getItem("sessionId") === "") {
      const newSession = generateSessionId();
      localStorage.setItem("sessionId", newSession);
      setSessionId(newSession);
    }
  }, []);

  // // Keep sessionId in sync
  // useEffect(() => {
  //   localStorage.setItem("sessionId", sessionId);
  // }, [sessionId]);

  // Create a fingerprint for each visitor
  useEffect(() => {
    const initializeFingerprint = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        const visitorId = result.visitorId;
        localStorage.setItem("visitorId", visitorId);
        setVisitorId(visitorId);
      } catch (error) {
        console.error("Error fetching fingerprint:", error);
      }
    };
    initializeFingerprint();
  }, []);

  // ------------------------------------------
  // HANDLERS
  // ------------------------------------------
  const handlePasswordSubmit = (email) => {
    // Called when user logs in from <LoginBox>
    const authToken = getCookie("authToken");
    if (authToken) {
      setShowOverlay(false);
    }

    // Create a session if needed
    if (!sessionId) {
      const newSessionId = generateSessionId();
      localStorage.setItem("sessionId", newSessionId);
      setSessionId(newSessionId);
    }

    // If user was guest before, associate guest chats with new user
    if (localStorage.getItem("email") !== "guest@gmail.com") {
      const currentSessionId = localStorage.getItem("sessionId");
      updateGuestChatsToLoggedInUser(currentSessionId, email);
    }

    // Update localStorage
    localStorage.setItem("login_handle", "false");
    setLoginHandle(false);
    localStorage.setItem("email", email);
    setEmail(email);

    // Show success, close overlay
    displayAlert();
    setShowOverlay(false);

    inputRef.current?.focus();
    navigate("/");
  };

  // Called if user hits message limit => show the login overlay
  const triggerLoginOverlay = () => {
    setOverlayView("login");    // Show the login form
    setShowOverlay(true);
    setLoginHandle(true);
    localStorage.setItem("login_handle", "true");
  };

  // Additional helpers to show signup or forgot forms (if needed)
  const triggerSignupOverlay = () => {
    setOverlayView("signup");
    setShowOverlay(true);
  };

  const triggerForgetOverlay = () => {
    setOverlayView("forget");
    setShowOverlay(true);
  };

  // Close the overlay
  const closeOverlay = () => {
    setShowOverlay(false);
    setOverlayView(null);
  };

  // ------------------------------------------
  // RENDER
  // ------------------------------------------
  return (
    <div className="mainpage-container">
      {/* 1) The overlay (only show if showOverlay=true). 
          We pass in the "overlayView" to decide which form to render. 
      */}

      {/* 2) Session Expiration Warning */}
      {showSessionError && (
        <SessionErrorBox message="Session expired. Please log in again." />
      )}

      {/* 3) Sidebar (left side) */}
      <Sidebar email={email}   generateSessionId={generateSessionId} />

      {/* 4) Chat area */}
        {showOverlay && (
          <OverlayMessage
            currentView={overlayView}
            onClose={closeOverlay}
            onPasswordSubmit={handlePasswordSubmit}
            onSignup = {triggerSignupOverlay}
            onForget = {triggerForgetOverlay}
            onLogin = {triggerLoginOverlay}
          />
        )}
      <div className="chat-container">
        <Chat
          sessionId={sessionId}
          email={email}
          visitorId={visitorId}
          onLimitReached={triggerLoginOverlay}
          onSignup={triggerSignupOverlay}
          />
      </div>
    </div>
  );
};

export default MainPage;
