import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root'));
window.alert = (message) => {
  const event = new CustomEvent("customAlert", { detail: message });
  window.dispatchEvent(event);
};
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
