// src/service/chat.js

import axios from "axios";

// Set your base URL for axios from environment variables
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_CONTROLLER_URL;

/**
 * fetchAnswerFromQuery
 * Calls the /controller/v3/user_chat endpoint to get an answer.
 *
 * @param {Object} payload - Must have { query, sessionId, messageId, userId }
 * @returns {Promise<Object>} The API response data
 */
export const fetchAnswerFromQuery = async (payload = {}) => {
  const { query, sessionId, messageId, userId } = payload;

  // Check for required fields
  if (!query || !sessionId || !messageId || !userId) {
    throw new Error("Missing required fields: query, sessionId, messageId, userId");
  }

  try {
    // POST to /controller/v3/user_chat
    const response = await axios.post("/controller/v3/user_chat", {
      query,
      sessionId,
      messageId,
      userId,
    });

    // Return the server's response data
    return response.data;
  } catch (error) {
    console.error("Error fetching from /controller/v3/user_chat:", error);
    throw error;
  }
};
