import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import RecentChats from "./recentchat"; // Import the RecentChats component
import "../Css/sidebar.css";
import setting from "../assets/icons/svg/settings.svg";

const Sidebar = ({ email , generateSessionId}) => {
    const [activeButton, setActiveButton] = useState(null);
    const location = useLocation();
    const isV2 = location.pathname.startsWith("/v2")
    const [recentChatsKey, setRecentChatsKey] = useState(0); 
    const navigate = useNavigate();

    // console.log("me haha hu side par par ", email);

    // Function to handle button clicks
    const handleButtonClick = (buttonId) => {
      // Set the clicked button as active
      setActiveButton(buttonId);
      // Check if the clicked button is the one intended to clear the conversation
      if (buttonId !== "newtab") { 
        // Show a popup notification indicating the page is under development
        alert("This page is under work, please try after some time.");
      }
      
      // Clear conversation in localStorage
      localStorage.setItem("conversation", JSON.stringify([]));
      
      // Generate new session ID
      const sessionId = generateSessionId();
      localStorage.setItem("sessionId", sessionId);
      
      // For new chat button, dispatch a special event
      if (buttonId === "newtab") {
        // Create payload with title "New Chat"
        const newChatPayload = {
          sessionId: sessionId,
          email: email || localStorage.getItem("email") || "guest@gmail.com",
          title: "New Chat",
          timestamp: new Date().toISOString(),
          isEmptyChat: true // Flag to identify this as an empty "New Chat" entry
        };
        
        // Dispatch a specific event for new chat button
        window.dispatchEvent(new CustomEvent('newChatButton', { 
          detail: newChatPayload 
        }));
      }
    };
    

    return (
        <div className="sidebar">
          <div className="sidebar_inner">
            {/* Upper Part - Three Buttons */}
            <div className="topSection">
              <div className="chatmro">
                <p>
                  ChatMRO<span className="version-name">Alpha</span>
                </p>
                <button
                  className={`${activeButton === "newtab" ? "active" : ""} new-chat`}
                  onClick={() => handleButtonClick("newtab")}
                >
                  <span>+</span> New Chat
                </button>
              </div>
            </div>
            {email !== "guest@gmail.com" && (
              <>
                <div className="upper-buttons">
                  {/* <button
                    className={`${
                      activeButton === "button1" ? "active" : ""
                    } sidebar_options`}
                    onClick={() => handleButtonClick("button1")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.429"
                      height="20.5"
                      viewBox="0 0 21.429 20.5"
                    >
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M11.952,2.085a2.177,2.177,0,0,0-1.459.555l-.02.02L1.618,11.5a.5.5,0,0,0,.711.714l1.245-1.24v8.084a3.03,3.03,0,0,0,3.03,3.03H9.636a.5.5,0,0,0,.5-.5v-7.2h3.623v7.2a.5.5,0,0,0,.5.5h2.947a3.03,3.03,0,0,0,3.03-3.03c0,.025,0,.014-.006-.044l0-.033,0-.033c.005-.049.006-.051.006-.044V10.958l1.245,1.253a.493.493,0,0,0,.356.148.5.5,0,0,0,.354-.145l0,0a.5.5,0,0,0,0-.712L13.424,2.66l-.013-.012h0a2.181,2.181,0,0,0-1.458-.561Zm0,1.009a1.183,1.183,0,0,1,.764.285l.011.008,6.513,6.554V18.9c0-.029,0-.032.006.029l0,.036,0,.034c-.006.052-.008.067-.008.054a2.028,2.028,0,0,1-2.022,2.022H14.771v-7.2a.5.5,0,0,0-.5-.5H9.637a.5.5,0,0,0-.5.5v7.2H6.605a2.028,2.028,0,0,1-2.022-2.022V10.128c0,.029,0,.019,0-.025v-.14l6.6-6.587a1.184,1.184,0,0,1,.767-.282Z"
                        transform="translate(-1.217 -1.835)"
                        fill="rgba(27,33,49,0.8)"
                        stroke="rgba(27,33,49,0.8)"
                        strokeWidth="0.5"
                      />
                    </svg>
                    Home
                  </button>
      
                  <button
                    className={`${
                      activeButton === "button2" ? "active" : ""
                    } sidebar_options`}
                    onClick={() => handleButtonClick("button2")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.343"
                      height="22.137"
                      viewBox="0 0 20.343 22.137"
                    >
                      <g
                        id="Group_3148"
                        data-name="Group 3148"
                        transform="translate(-5.15 -4.09)"
                      >
                        <path
                          id="Path_4445"
                          data-name="Path 4445"
                          d="M8.951,8.872A.576.576,0,0,1,9.527,8.3H17.7a.576.576,0,0,1,0,1.153H9.527A.576.576,0,0,1,8.951,8.872Zm.576,3.847H17.7a.576.576,0,0,0,0-1.153H9.527a.576.576,0,0,0,0,1.153Zm0,3.269H17.7a.576.576,0,0,0,0-1.153H9.527a.576.576,0,0,0,0,1.153Zm6.3,2.123H9.523a.576.576,0,1,0,0,1.153h6.305a.576.576,0,0,0,0-1.153Zm9.615,3.671a4.354,4.354,0,0,1-8.392,1.631H8.082A2.882,2.882,0,0,1,5.2,20.532V7.022A2.882,2.882,0,0,1,8.082,4.14H19.148A2.882,2.882,0,0,1,22.03,7.022V17.535a4.362,4.362,0,0,1,3.413,4.248Zm-8.667.478a4.158,4.158,0,0,1,4.1-4.815V7.022a1.711,1.711,0,0,0-1.729-1.729H8.082A1.711,1.711,0,0,0,6.353,7.022v13.51a1.711,1.711,0,0,0,1.729,1.729Zm7.515-.478a3.206,3.206,0,1,0-3.206,3.206A3.206,3.206,0,0,0,24.29,21.783Zm-3.206-2.305a.576.576,0,0,0-.576.576v1.331l-.692.27a.576.576,0,0,0,.418,1.074l1.058-.414a.576.576,0,0,0,.367-.537V20.049a.576.576,0,0,0-.575-.571Z"
                          transform="translate(0 0)"
                          fill="rgba(27,33,49,0.8)"
                          stroke="rgba(27,33,49,0.8)"
                          strokeWidth="0.1"
                        />
                      </g>
                    </svg>
                    Quote History
                  </button>
                  }*
                  <button
                    className={`${
                      activeButton === "button3" ? "active" : ""
                    } sidebar_options`}
                    onClick={() => handleButtonClick("button3")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.041"
                      height="19.3"
                      viewBox="0 0 21.041 19.3"
                    >
                      <g
                        id="Group_14"
                        data-name="Group 14"
                        transform="translate(-13.831 -969.212)"
                      >
                        <path
                          id="Path_11"
                          data-name="Path 11"
                          d="M25.1,969.362a9.613,9.613,0,0,0-9.14,6.531l-.891-1.583a.578.578,0,1,0-1.008.567L15.65,977.7a.576.576,0,0,0,.765.234L19.23,976.5a.576.576,0,1,0-.522-1.026l-1.673.855a8.347,8.347,0,1,1-.171,4.462.576.576,0,1,0-1.116.261A9.6,9.6,0,1,0,25.1,969.362Zm.054,4.309a.576.576,0,0,0-.513.585v4.606a.576.576,0,0,0,.216.45l2.879,2.3a.576.576,0,0,0,.72-.9L25.8,978.583v-4.327a.576.576,0,0,0-.639-.585Z"
                          fill="rgba(27,33,49,0.8)"
                          stroke="#454a58"
                          strokeWidth="0.3"
                        />
                      </g>
                    </svg>
                    Chat History
                  </button> 
                </div>*/}

                <button
                    className={'sidebar_options'}
                    
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="21.041"
                      height="19.3"
                      viewBox="0 0 21.041 19.3"
                    >
                      <g
                        id="Group_14"
                        data-name="Group 14"
                        transform="translate(-13.831 -969.212)"
                      >
                        <path
                          id="Path_11"
                          data-name="Path 11"
                          d="M25.1,969.362a9.613,9.613,0,0,0-9.14,6.531l-.891-1.583a.578.578,0,1,0-1.008.567L15.65,977.7a.576.576,0,0,0,.765.234L19.23,976.5a.576.576,0,1,0-.522-1.026l-1.673.855a8.347,8.347,0,1,1-.171,4.462.576.576,0,1,0-1.116.261A9.6,9.6,0,1,0,25.1,969.362Zm.054,4.309a.576.576,0,0,0-.513.585v4.606a.576.576,0,0,0,.216.45l2.879,2.3a.576.576,0,0,0,.72-.9L25.8,978.583v-4.327a.576.576,0,0,0-.639-.585Z"
                          fill="rgba(27,33,49,0.8)"
                          stroke="#454a58"
                          strokeWidth="0.3"
                        />
                      </g>
                    </svg>
                    Chat History
                  </button> 
                </div>    
                {/* Lower Part - Recent Chats */}
                <div className="recent-chats-container">
                  <RecentChats
                    email={email}
                    key={recentChatsKey}
                  />
                </div>
      
            {/* Settings Button */}
            {/* <div className="settingbtn">
              <button>
                <img src={setting} alt="Settings Icon" />
                Settings
              </button>
            </div> */}
              </>
            )}
          </div>
        </div>
      );
    };
export default Sidebar;
