import axios from 'axios';
import Cookies from 'js-cookie';
const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// console.log(API_BASE_URL);
const api = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});
export const loginUser = async (email, password) => {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    try {
        const response = await api.post('/token', params);
        const { access_token, token_type } = response.data;

        if (token_type === 'bearer') {
            Cookies.set('authToken', access_token, { expires: 5, secure: true, sameSite: 'Strict' });
            return access_token;
        } else {
            throw new Error('Unsupported token type');
        }
    } catch (error) {
        throw new Error(error.response?.data?.detail || 'Authentication failed');
    }
};

export const getToken = () => {
    return Cookies.get('authToken');
};

export const logoutUser = () => {
    Cookies.remove('authToken');
};

